<template>
  <div class="container wrapper">
    <h1 class="pageHeader mt-12 mb-8">Display Information</h1>
    <div class="md-font mb-12 pb-5">
      Hide
      {{
        ['agency', 'production_house'].includes(me.role) ? 'company ' : ''
      }}profile info from public view
    </div>
    <div class="d-flex">
      <div class="align-self-center">Show All</div>
      <div class="ml-auto">
        <v-switch
          v-model="showAll"
          inset
          dark
          color="primary"
          @click="showAllOnChange"
        ></v-switch>
      </div>
    </div>
    <hr class="divider my-6" />
    <div class="w-100 mb-12 pb-12">
      <div
        class="w-100"
        v-for="(displayInfos, index) in formattedDisplayInfos"
        :key="index"
      >
        <h3>{{ displayInfos.label }}</h3>
        <div class="listBg mx-3 px-5">
          <div v-for="(content, idx) in displayInfos.content" :key="idx">
            <div class="d-flex">
              <div class="align-self-center">{{ content.label }}</div>
              <div class="ml-auto">
                <v-switch
                  v-model="displayArr"
                  inset
                  multiple
                  :value="content.id"
                  dark
                  color="primary"
                  @click="submit"
                ></v-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-center mt-10 mb-12 pb-12"
      v-if="$route.params.navigateToEditProfile"
    >
      <v-btn class="btn1" @click="navigateToEditProfile"> Done </v-btn>
      <div class="mt-6">
        <a
          class="white--text underlineBtn cursor-pointer"
          @click="navigateToEditProfile(false)"
        >
          Back
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    PROFILE_UPDATE_DISPLAY_INFORMATION,
    PROFILE_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE
  } from '@/store/profile.module';
  import { AUTH_ME } from '@/store/auth.module';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'DisplayInformations',
    data: () => ({
      displayArr: [],
      showAll: false,
      socialPlatforms: [
        'WEBSITE_LINK',
        'FACEBOOK',
        'INSTAGRAM',
        'TWITTER',
        'LINKEDIN',
        'YOUTUBE',
        'TIKTOK',
        'TWITCH'
      ],
      medias: [
        'CV_FILE',
        'COMPANY_SHOWREEL_VIDEO',
        'COMPANY_SHOWREEL_IMAGE',
        'INTRO_VIDEO',
        'GALLERY'
      ]
    }),
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      },
      constantsDisplayInformation() {
        return this.$store.getters['constants/displayInformations'];
      },
      roleDisplayInfo() {
        return this.constantsDisplayInformation.filter((x) =>
          x.showRole.includes(this.me.role)
        );
      },
      formattedDisplayInfos() {
        let arr = [];
        this.roleDisplayInfo.forEach((x) => {
          if (this.socialPlatforms.includes(x.key)) {
            arr = this.categoriseInfos(arr, x, {
              label: 'Social Platforms',
              category: 'social-platforms'
            });
          } else if (this.medias.includes(x.key)) {
            arr = this.categoriseInfos(arr, x, {
              label: 'Medias',
              category: 'medias'
            });
          } else {
            arr = this.categoriseInfos(arr, x);
          }
        });
        return arr;
      },
      updateDisplayInformationComplete() {
        return this.$store.state.profile.updateDisplayInformation.complete;
      }
    },
    watch: {
      me(val) {
        if (val?.display_information_ids) {
          this.displayArr = val.display_information_ids;
          const roleDisplayArr = this.roleDisplayInfo.map((x) => x.id);
          this.showAll =
            roleDisplayArr.sort().join(',') ===
            this.displayArr.sort().join(',');
        }
      },
      displayArr(val) {
        const containsAll = (arr1, arr2) =>
          arr2.every((arr2Item) => arr1.includes(arr2Item));
        const sameMembers = (arr1, arr2) =>
          containsAll(arr1, arr2) && containsAll(arr2, arr1);
        const ids = this.roleDisplayInfo.map((x) => x.id);
        this.showAll = sameMembers(val, ids);
      },
      updateDisplayInformationComplete() {
        let response = this.$store.state.profile.updateDisplayInformation;
        if (response.complete) {
          this.initialUpdateDisplayInformationState();
          this.getMe();
        }
      }
    },
    methods: {
      categoriseInfos(
        arr,
        info,
        d = { label: 'General', category: 'general' }
      ) {
        const findCategory = arr.find((x) => x.category == d.category);
        if (!findCategory) {
          arr.push({
            label: d.label,
            category: d.category,
            content: [info]
          });
          return arr;
        }
        findCategory.content.push(info);
        return arr;
      },
      showAllOnChange() {
        if (this.showAll) {
          this.displayArr = this.roleDisplayInfo.map((x) => x.id);
        } else {
          this.displayArr = [];
        }
        this.submit();
      },
      submit() {
        this.$nextTick(() => {
          this.$store.dispatch(PROFILE_UPDATE_DISPLAY_INFORMATION, {
            data: this.displayArr
          });
        });
      },
      getMe() {
        this.$store.dispatch(AUTH_ME);
      },
      initialUpdateDisplayInformationState() {
        this.$store.dispatch(PROFILE_INITIAL_UPDATE_DISPLAY_INFORMATION_STATE);
      },
      navigateToEditProfile(goLive = true) {
        this.$router.push({
          name: ROUTE_NAME[
            this.me.role == 'talent' ? 'PROFILE_INFO' : 'COMPANY_INFO'
          ],
          params: {
            goLive,
            lastStep: !goLive
          }
        });
      }
    },
    mounted() {
      if (this.me?.display_information_ids) {
        this.displayArr = this.me.display_information_ids;
        const roleDisplayArr = this.roleDisplayInfo.map((x) => x.id);
        this.showAll = roleDisplayArr.every((x) => this.displayArr.includes(x));
      }
    }
  };
</script>

<style scoped>
  .listBg {
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
</style>
